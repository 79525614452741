// import
import SignUp from "views/Auth/SignUp.js";
import SignIn from "views/Auth/SignIn.js";
import VerifyEmail from "views/Auth/VerifyEmail.js";
import PricingModule from "views/Auth/PricingModule.js";
import Dashboard from "views/Dashboard";
import UserManagement from "views/UserManagement";
import Exam from "views/Exam";
import Achievement from "views/Exam/Achievement";
import Reports from "views/Exam/Reports";
import Examination from "views/Exam/Examination";
// import Pages from "views/Dashboard/Pages";
// import Uac from "views/Dashboard/Uac";
// import Subject from "views/Dashboard/Subject";
// import Division from "views/Dashboard/Division";
// import Question from "views/Dashboard/Question";
// import ExamManagement from "views/Dashboard/ExamManagement";
// import Pricing from "views/Dashboard/Pricing";
// import QuestionPassage from "views/Dashboard/QuestionPassage";
// import PromoCode from "views/Dashboard/PromoCode";
// import Tables from "views/Dashboard/Tables";
// import Billing from "views/Dashboard/Billing";
// import RTLPage from "views/Dashboard/RTL";
// import Profile from "views/Dashboard/Profile";

import { RocketIcon, HomeIcon, StatsIcon, PersonIcon, DocumentIcon } from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    noRender: true,
  },
  {
    path: "/signin",
    route: "/signin",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    noRender: true,
  },
  {
    path: "/verify-user",
    route: "/verify-user",
    name: "Verify User",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: VerifyEmail,
    layout: "/auth",
    noRender: true,
  },
  {
    path: "/dashboard",
    route: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/pricing",
    route: "/pricing",
    name: "Pricing",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: PricingModule,
    layout: "/admin",
  },
  {
    path: "/student-management",
    route: "/student-management",
    name: "Student Management",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: UserManagement,
    layout: "/admin",
  },
  {
    path: "/examination/:id",
    route: "/examination",
    name: "Examination",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: Examination,
    layout: "/admin",
  },
  {
    path: "/exam",
    route: "/exam",
    name: "Exam",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: Exam,
    layout: "/admin",
  },
  {
    path: "/achievement",
    route: "/achievement",
    name: "Achievement",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: Achievement,
    layout: "/admin",
  },
  {
    path: "/reports",
    route: "/reports",
    name: "Reports",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: Reports,
    layout: "/admin",
  },
  // {
  //   path: "/user_management",
  //   route: "/user_management",
  //   name: "User Management",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   component: UserManagement,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pages",
  //   route: "/pages",
  //   name: "Page Manager",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Pages,
  //   layout: "/admin",
  // },
  // {
  //   path: "/uac/:id",
  //   route: "/uac",
  //   name: "User Access Control",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Uac,
  //   layout: "/admin",
  //   hiddenMenu: true,
  // },
  // {
  //   path: "/subject_management",
  //   route: "/subject_management",
  //   name: "Subject Management",
  //   rtlName: "لوحة القيادة",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: Subject,
  //   layout: "/admin",
  // },
  // {
  //   path: "/division_management",
  //   route: "/division_management",
  //   name: "Division Management",
  //   rtlName: "لوحة القيادة",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: Division,
  //   layout: "/admin",
  // },
  // {
  //   path: "/question_passage",
  //   route: "/question_passage",
  //   name: "Question Passage",
  //   rtlName: "لوحة القيادة",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: QuestionPassage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/question_management",
  //   route: "/question_management",
  //   name: "Question Management",
  //   rtlName: "لوحة القيادة",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: Question,
  //   layout: "/admin",
  // },
  // {
  //   path: "/exam_management",
  //   route: "/exam_management",
  //   name: "Exam Management",
  //   rtlName: "لوحة القيادة",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: ExamManagement,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pricing",
  //   route: "/pricing",
  //   name: "Pricing",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   component: Pricing,
  //   layout: "/admin",
  // },
  // {
  //   path: "/promo_code",
  //   route: "/promo_code",
  //   name: "Promo Code",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   component: PromoCode,
  //   layout: "/admin",
  // },
  // {
  //   path: "/payment_config",
  //   route: "/payment_config",
  //   name: "Payment Gateway Config",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   component: Dashboard,
  //   layout: "/admin",
  // },

  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: <HomeIcon color="inherit" />,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon color="inherit" />,
  //   component: Billing,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color="inherit" />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       rtlName: "لوحة القيادة",
  //       icon: <DocumentIcon color="inherit" />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
