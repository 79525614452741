import React, { useState, useEffect } from "react";
import { Link as ReactLink } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { API_ENDPOINT } from "../../constants";
import {
  Flex,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  SimpleGrid,
  useColorModeValue,
  Link as ChakraLink,
} from "@chakra-ui/react";

import MiniStatistics from "../Dashboard/Dashboard/components/MiniStatistics";

function Exam() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const iconBoxInside = useColorModeValue("white", "white");
  const [mockExams, setMockExams] = useState([]);
  const [practiceExams, setPracticeExams] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getstudentexams`, requestHeader);
      const examsList = response?.data?.examsList ?? [];
      setPracticeExams(examsList.filter((list) => list.type === 2));
      setMockExams(examsList.filter((list) => list.type === 1));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Box>
        <Tabs>
          <TabList>
            <Tab>Mock</Tab>
            <Tab>Practice</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                <Flex flexDirection="column">
                  <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
                    {mockExams?.map((mockExam) => (
                      <ChakraLink
                        as={ReactLink}
                        to={`/admin/examination/${mockExam?.id}`}
                        color="blue.500"
                      >
                        <MiniStatistics
                          title={mockExam?.subject_id > 0 ? mockExam?.subject_name : "All Subjects"}
                          amount={
                            mockExam?.time_limit > 0
                              ? `Time Limit : ${mockExam?.time_limit} Minutes`
                              : "Time Limit : Practice"
                          }
                          icon={<Text color={iconBoxInside}>{mockExam?.question_count}</Text>}
                        />
                      </ChakraLink>
                    ))}
                  </SimpleGrid>
                </Flex>
              </Box>
            </TabPanel>

            <TabPanel>
              <Box>
                <Flex flexDirection="column">
                  <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
                    {practiceExams?.map((mockExam) => (
                      <ChakraLink
                        as={ReactLink}
                        to={`/admin/examination/${mockExam?.id}`}
                        color="blue.500"
                      >
                        <MiniStatistics
                          title={mockExam?.subject_id > 0 ? mockExam?.subject_name : "All Subjects"}
                          amount={
                            mockExam?.time_limit > 0
                              ? `Time Limit : ${mockExam?.time_limit} Minutes`
                              : "Time Limit : Practice"
                          }
                          icon={<Text color={iconBoxInside}>{mockExam?.question_count}</Text>}
                        />
                      </ChakraLink>
                    ))}
                  </SimpleGrid>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default Exam;
