import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Center,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import mttLogo from "assets/img/my-transfer-teacher.png";
import { PROJECT_TITLE, API_ENDPOINT } from "../../constants";
import { isEmailValid, isValidSchoolPassword, isValidPhone } from "../../Utils";

function SignUp() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState();
  const [formData, setFormData] = useState({
    account_type: "0",
    school_name: "",
    name: "",
    mobile_number: "",
    email: "",
    password: "",
  });

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (formData?.account_type !== "0" && formData?.account_type !== "1") {
      // Handle validation error, e.g., show an error message
      setError("Please select valid Account Type");
      return;
    }

    if (formData?.account_type === "1" && !formData.school_name) {
      setError("School Name required");
      return;
    }

    if (!formData.name) {
      setError("Name required");
      return;
    }

    if (!isValidPhone(formData?.mobile_number)) {
      setError("Please enter a valid mobile number.");
      return;
    }

    // Validate the email
    if (!isEmailValid(formData?.email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Validate the password
    if (!isValidSchoolPassword(formData?.password)) {
      setError("Password must be at least 5 characters and include alphabet, numeric");
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINT}student-registration`, formData);

      setErrorType(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        let errorString = "";
        if (errors) {
          Object.keys(errors).forEach((key) => {
            errorString += `${errors[key].join(" ")} `;
          });
          setError(errorString);
        }
      } else if (response?.data?.status === true) {
        setError(response?.data?.message);
        setFormData({
          account_type: "0",
          school_name: "",
          name: "",
          mobile_number: "",
          email: "",
          password: "",
        });
      }
    } catch (error) {
      console.error("error", error);
      setError("Error during login:", error);
    }
  };

  return (
    <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
      <Box
        position="absolute"
        minH={{ base: "100vh", md: "50vh" }}
        w={{ base: "100vw", md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BgSignUp}
        bgSize="cover"
        mx={{ md: "auto" }}
        className="signup-bg"
      ></Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb={{ base: "60px", md: "0" }}
        mt={{ base: "60px", md: "0" }}
      >
        <Flex
          direction="column"
          background="transparent"
          borderRadius="15px"
          p={{ base: "20px", md: "40px" }}
          mx={{ base: "10px", md: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Center>
            <Image
              align="center"
              src={mttLogo}
              alt={PROJECT_TITLE}
              mb="8px"
              width={{ base: "80px", md: "100px", lg: "100px" }}
            />
          </Center>
          <Text fontSize="2xl" color={textColor} fontWeight="bold" textAlign="center" mb="8px">
            Registration
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Account Type
              </FormLabel>
              <RadioGroup
                value={formData.account_type}
                onChange={(value) => handleChange("account_type", value.toString())}
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                mb="8px"
                size="sm"
              >
                <Stack direction="row">
                  <Radio value="0">Parent</Radio>
                  <Radio value="1">School</Radio>
                </Stack>
              </RadioGroup>

              {formData.account_type === "1" && (
                <>
                  <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                    School Name
                  </FormLabel>
                  <Input
                    fontSize="sm"
                    ms="2px"
                    borderRadius="15px"
                    type="text"
                    placeholder="Your school Name"
                    mb="8px"
                    size="sm"
                    autoComplete="off"
                    name="school_name"
                    onChange={(e) => handleChange("school_name", e.target.value)}
                    value={formData.school_name}
                  />
                </>
              )}

              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                {formData.account_type === "1" ? "Contact Person " : "Your"} Name
              </FormLabel>
              <Input
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                type="text"
                placeholder="Name"
                mb="8px"
                size="sm"
                autoComplete="off"
                name="name"
                onChange={(e) => handleChange("name", e.target.value)}
                value={formData.name}
              />

              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Mobile Number
              </FormLabel>
              <InputGroup
                size="sm"
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                mb="8px"
                autoComplete="off"
              >
                <InputLeftAddon children="+44" />
                <Input
                  type="number"
                  min="1111111111"
                  max="9999999999"
                  placeholder="Mobile number"
                  size="sm"
                  name="mobile_number"
                  onChange={(e) => handleChange("mobile_number", e.target.value)}
                  value={formData.mobile_number}
                />
              </InputGroup>

              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                type="email"
                placeholder="Your email address"
                mb="8px"
                size="sm"
                autoComplete="off"
                name="email"
                onChange={(e) => handleChange("email", e.target.value)}
                value={formData.email}
              />
              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                type="password"
                placeholder="Your password"
                mb="8px"
                size="sm"
                autoComplete="off"
                name="password"
                onChange={(e) => handleChange("password", e.target.value)}
                value={formData.password}
              />
              <Button
                type="submit"
                bg="teal.300"
                fontSize="10px"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="8px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
              >
                SIGN UP
              </Button>
            </FormControl>
            {error && <Text color={errorType === true ? "green" : "red"}>{error}</Text>}
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Already have an account ?{" "}
              <NavLink color={titleColor} as="span" ms="5px" to="/auth/signin" fontWeight="bold">
                Sign In
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
