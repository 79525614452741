import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Flex,
  Text,
  Center,
  Image,
  useColorModeValue,
  Icon,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { FaCheckCircle, FaCross } from "react-icons/fa";
import { CheckCircleIcon } from "@chakra-ui/icons";

// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import mttLogo from "assets/img/my-transfer-teacher.png";
import { PROJECT_TITLE, API_ENDPOINT } from "../../constants";
import { isEmailValid } from "../../Utils";

function VerifyEmail() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const verified = searchParams.get("verified");
  const email = searchParams.get("email");
  const usercode = searchParams.get("usercode");
  const bgColor = useColorModeValue("white", "gray.700");
  const [isLoading, setIsLoading] = useState(true);
  const [verified, setVerified] = useState();
  const [message, setMessage] = useState();

  const verifyUser = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}verify-user?email=${email}&usercode=${usercode}`,
      );

      setVerified(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        let errorString = "";
        if (errors) {
          Object.keys(errors).forEach((key) => {
            errorString += `${errors[key].join(" ")} `;
          });
          setMessage(errorString);
          setIsLoading(false);
          setVerified(false);
        }
      } else if (response?.data?.status === true) {
        setMessage(response?.data?.message);
        setIsLoading(false);
        setVerified(true);
      }
    } catch (error) {
      console.error("error", error);
      setMessage("Error during login:", error);
    }
  };

  const redirecttoHome = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    if (email === null || usercode === null) {
      setIsLoading(false);
      setVerified(false);
      setMessage("Invalid URL");
    } else {
      if (isEmailValid(email) && usercode !== "") {
        verifyUser();
      } else {
        setIsLoading(false);
        setVerified(false);
        setMessage("Invalid Data");
      }
    }
  }, [email, usercode]);

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      p={{ md: "20px" }}
    >
      <Box
        position="absolute"
        minH={{ base: "100vh", md: "100vh" }}
        w={{ base: "100vw" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BgSignUp}
        bgSize="cover"
        className="signup-bg"
      ></Box>
      <Flex alignItems="center" justifyContent="center" mt={{ base: "112px", md: "24px" }}>
        <Flex
          direction="column"
          background="transparent"
          borderRadius="15px"
          p={{ base: "20px", md: "40px" }}
          mx={{ base: "10px", md: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Center flexDirection="column" textAlign="center" spacing="4">
            <Image
              align="center"
              src={mttLogo}
              alt={PROJECT_TITLE}
              mb="8px"
              width={{ base: "80px", md: "120px", lg: "120px" }}
            />
            {isLoading ? (
              <>
                <Spinner size="xl" color="blue.500" mt="20px" />
                <Text fontSize="1xl" fontWeight="bold" mb="12px" mt="12px">
                  Verifying user. Please wait....
                </Text>
              </>
            ) : verified ? (
              <>
                <Icon as={FaCheckCircle} color="green.500" fontSize="7xl" />
                <Text fontSize="1xl" fontWeight="bold" mb="12px" mt="12px">
                  {message ?? "Congratulations! Your account has been successfully verified."}
                </Text>
                <Button colorScheme="green" variant="solid" w="200px" onClick={redirecttoHome}>
                  OK
                </Button>
              </>
            ) : (
              <>
                <Icon as={FaCheckCircle} color="red.500" fontSize="7xl" />
                <Text fontSize="1xl" fontWeight="bold" mb="12px" mt="12px">
                  {message ??
                    "Your account is not verified. Please verify your account to continue."}
                </Text>
                <Button colorScheme="red" variant="solid" w="200px" onClick={redirecttoHome}>
                  OK
                </Button>
              </>
            )}
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default VerifyEmail;
