import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SimpleGrid,
  Center,
  Stat,
  StatHelpText,
  Text,
  StatNumber,
  Heading,
  Icon,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";

import { FaTrophy } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { API_ENDPOINT } from "../../constants";

const Reports = () => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const [entities, setEntity] = useState([]);
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}get-results`, requestHeader);
      setEntity(response?.data?.results ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Center h="100vh" flexDirection="column">
      <Icon as={FaTrophy} boxSize={12} color="green.500" />
      <Heading className="neonText" size="1xl">
        Achievements
      </Heading>
      <SimpleGrid columns={{ sm: 2, md: 2, xl: 4 }} spacing="24px">
        {entities?.map((entity) => (
          <Card mt="12px" p="12px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <Text fontSize="sm" color="blue.400" fontWeight="bold" pb=".1rem">
                    {entity?.exam_code}
                  </Text>

                  <StatNumber fontSize="sm" color={textColor}>
                    Total Quesions: {entity?.total_question_count}
                  </StatNumber>
                  <StatNumber fontSize="sm" color={textColor}>
                    Answeered Quesions: {entity?.total_question_answered}
                  </StatNumber>
                  <StatNumber fontSize="sm" color={textColor}>
                    Correct Answers: {entity?.total_answer_matching}
                  </StatNumber>
                </Stat>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
    </Center>
  );
};

export default Reports;
