import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "../constants";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("authState", serializedState);
  } catch (error) {
    // Handle errors here
  }
};

const initialState = loadState() || {
  isAuthenticated: false,
  authToken: null,
  adminInfo: null,
  uacPageRoutes: null,
  loading: false,
  error: null,
};

export const fetchAdminInfo = createAsyncThunk("admin/fetchAdminInfo", async (_, { getState }) => {
  const token = getState().auth.authToken;
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    try {
      const response = await fetch(`${API_ENDPOINT}schinfo`, { headers });
      if (response.ok) {
        const adminInfo = await response.json();

        let uacPageRoutes = [];
        if (adminInfo?.admin?.account_type === 1 || adminInfo?.admin?.account_type === 0) {
          if (adminInfo?.admin?.status > 1) {
            uacPageRoutes = ["/dashboard", "/student-management", "/reports"];
          } else {
            uacPageRoutes = ["/dashboard", "/pricing"];
          }
        }
        if (adminInfo?.admin?.account_type === 2) {
          uacPageRoutes = ["/dashboard", "/exam", "/examination", "/achievement"];
        }

        return { adminInfo: adminInfo?.admin, authToken: token, uacPageRoutes: uacPageRoutes };
      } else {
        return { adminInfo: null, authToken: token, uacPageRoutes: null };
      }
    } catch (error) {
      // Handle network errors or other issues
      throw error;
    }
  }
});

export const handleInvalidToken = () => (dispatch) => {
  dispatch(logout());
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.authToken = action.payload?.authToken;
      saveState(state);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.authToken = null;
      state.loading = false;
      state.error = null;
      state.adminInfo = null;
      state.uacPageRoutes = null;
      saveState(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.adminInfo = null;
      })
      .addCase(fetchAdminInfo.fulfilled, (state, action) => {
        state.isAuthenticated = action?.payload?.adminInfo ? true : false;
        state.loading = false;
        state.error = null;
        state.adminInfo = action?.payload?.adminInfo ?? null;
        state.authToken = action?.payload?.authToken ?? null;
        state.uacPageRoutes = action?.payload?.uacPageRoutes ?? null;
        saveState(state);
      })
      .addCase(fetchAdminInfo.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.adminInfo = null;
        state.authToken = null;
        state.uacPageRoutes = null;
        state.loading = false;
        state.error = action?.error?.message;
        saveState(state);
      });
  },
});

export const { login, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
