import { Center, Heading, Icon } from "@chakra-ui/react";
import { FaTrophy } from "react-icons/fa";

const Reports = () => {
  return (
    <Center h="100vh" flexDirection="column">
      <Icon as={FaTrophy} boxSize={48} color="green.500" />
      <Heading className="neonText" size="2xl">
        Achievements
      </Heading>
    </Center>
  );
};

export default Reports;
