import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { useLocation } from "react-router-dom";
// Chakra imports
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  Box,
  Flex,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { API_ENDPOINT } from "../../constants";

function PricingModule() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verified = searchParams.get("verified");
  const bgColor = useColorModeValue("white", "gray.700");

  const [redirect, setRedirect] = useState(false);
  const [numberOfDevices, setNumberOfDevices] = useState(1);
  const [devicePrice, setDevicePrice] = useState(10);
  const [couponPrice, setCouponPrice] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const totalAmount = numberOfDevices * devicePrice - couponPrice;

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
    // Call API to validate promo code and update couponPrice
    // Example: fetch("/validatePromoCode", { method: "POST", body: JSON.stringify({ promoCode: event.target.value }) })
    // .then(response => response.json())
    // .then(data => setCouponPrice(data.discount));
  };

  const handlePayNow = async () => {
    const response = await axios.post(`${API_ENDPOINT}update-userpay`, {}, requestHeader);
    if (response?.data?.status === true) {
      window.location.href = "/";
    }
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      p={{ md: "20px" }}
    >
      <Box
        position="absolute"
        minH={{ base: "100vh", md: "100vh" }}
        w={{ base: "100vw" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgSize="cover"
        className="signup-bg"
      ></Box>
      <Flex alignItems="center" justifyContent="center" mt={{ base: "80px", md: "24px" }}>
        <Flex
          direction="column"
          background="transparent"
          borderRadius="15px"
          p={{ base: "20px", md: "40px" }}
          mx={{ base: "auto", md: "100px" }}
          w={{ base: "70%", md: "auto" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Box maxW="md" mx="auto" mt="8">
            <Flex direction={{ base: "column", md: "row" }} justify="space-between">
              <Stack spacing="4" mr={{ md: "4" }}>
                <FormControl>
                  <FormLabel>Number of Devices</FormLabel>
                  <Select
                    value={numberOfDevices}
                    onChange={(e) => setNumberOfDevices(parseInt(e.target.value))}
                  >
                    {[...Array(10).keys()].map((number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Price per Device ($)</FormLabel>
                  <Input
                    value={devicePrice}
                    onChange={(e) => setDevicePrice(parseFloat(e.target.value))}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Coupon Price ($)</FormLabel>
                  <Input
                    value={couponPrice}
                    onChange={(e) => setCouponPrice(parseFloat(e.target.value))}
                  />
                </FormControl>
              </Stack>

              <Stack spacing="4">
                <FormControl>
                  <FormLabel>Promo Code</FormLabel>
                  <Input
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    placeholder="Enter Promo Code"
                  />
                </FormControl>

                <Text>Total Amount: ${totalAmount.toFixed(2)}</Text>

                <FormControl>
                  <FormLabel>Select Payment Method</FormLabel>
                  <Select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Netbanking">Netbanking</option>
                  </Select>
                </FormControl>

                <Button onClick={handlePayNow} colorScheme="blue">
                  Pay Now
                </Button>
              </Stack>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PricingModule;
