import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/authSlice";
import { useHistory, Redirect } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Center,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import mttLogo from "assets/img/my-transfer-teacher.png";
import { PROJECT_TITLE, API_ENDPOINT } from "../../constants";
import { isValidSchoolPassword } from "../../Utils";

function SignIn() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // Redirect to the dashboard if already authenticated
  if (isAuthenticated) {
    return <Redirect to="/admin/dashboard" />;
  }

  const handleChange = (key, value) => {
    if (key === "username") {
      setFormData({ ...formData, [key]: value.trim() });
    } else {
      setFormData({ ...formData, [key]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate the username
    if (!formData?.username || formData?.username?.length < 5) {
      setError("Please enter valid username.");
      return;
    }

    // Validate the password
    if (!isValidSchoolPassword(formData?.password)) {
      setError("Password must be at least 5 characters and include alphabet, numeric");
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINT}student-login`, formData);
      const authToken = response?.data?.token;
      if (authToken) {
        setError("");
        setErrorType(true);
        dispatch(login({ authToken }));
        history.push("/admin/dashboard");
      } else {
        setError(`Login failed ${response?.data?.message}`);
        setErrorType(false);
      }
    } catch (error) {
      console.error("error", error);
      setError("Error during login:", error);
    }
  };

  return (
    <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
      <Box
        position="absolute"
        minH={{ base: "100vh", md: "50vh" }}
        w={{ base: "100vw", md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BgSignUp}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
        className="signup-bg"
      ></Box>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="80px">
        <Flex
          direction="column"
          background="transparent"
          borderRadius="15px"
          p={{ base: "20px", md: "40px" }}
          mx={{ base: "10px", md: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Center>
            <Image
              align="center"
              src={mttLogo}
              alt={PROJECT_TITLE}
              mb="8px"
              width={{ base: "80px", md: "150px", lg: "150px" }}
            />
          </Center>
          <Text fontSize="2xl" color={textColor} fontWeight="bold" textAlign="center" mb="8px">
            Login
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Login Id (Username)
              </FormLabel>
              <Input
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                type="text"
                placeholder="Username"
                mb="8px"
                size="sm"
                autoComplete="off"
                name="username"
                onChange={(e) => handleChange("username", e.target.value)}
                value={formData.username}
              />
              <FormLabel ms="2px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input
                fontSize="sm"
                ms="2px"
                borderRadius="15px"
                type="password"
                placeholder="Your password"
                mb="8px"
                size="sm"
                autoComplete="off"
                name="password"
                onChange={(e) => handleChange("password", e.target.value)}
                value={formData.password}
              />
              <Button
                type="submit"
                bg="teal.300"
                fontSize="10px"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="8px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
              >
                Login
              </Button>
            </FormControl>
            {error && <Text color={errorType === true ? "green" : "red"}>{error}</Text>}
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account ?{" "}
                <NavLink color={titleColor} as="span" ms="5px" to="/auth/signup" fontWeight="bold">
                  Registration
                </NavLink>
              </Text>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignIn;
