import React from "react";
import { Redirect } from "react-router-dom";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";

export default function Dashboard() {
  const { adminInfo } = useSelector(selectAuth);

  if (adminInfo?.status === 0) {
    return <Redirect to="/auth/signin" />;
  }

  if (adminInfo?.status === 1) {
    return <Redirect to="/admin/pricing" />;
  }

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {adminInfo?.status === 0 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100vh"
          bgGradient="linear(to-r, teal.500, teal.300)"
        >
          <Box p={8} maxW="md" borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
            <Flex alignItems="center" justifyContent="center">
              <WarningIcon boxSize={8} color="red.500" mr={4} />
              <Heading size="md" color="red.500">
                Invalid User!
              </Heading>
            </Flex>
            <Text mt={4}>
              We couldn't find your account. Please check your credentials and try again.
            </Text>
            <Button
              mt={4}
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                // Handle click event
              }}
            >
              Go Back
            </Button>
          </Box>
        </Flex>
      )}

      {adminInfo?.status === 1 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100vh"
          bgGradient="linear(to-r, teal.500, teal.300)"
        >
          <Box p={8} maxW="md" borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
            <Flex alignItems="center" justifyContent="center">
              <WarningIcon boxSize={8} color="red.500" mr={4} />
              <Heading size="md" color="red.500">
                Invalid User!
              </Heading>
            </Flex>
            <Text mt={4}>
              We couldn't find your account. Please check your credentials and try again.
            </Text>
            <Button
              mt={4}
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                // Handle click event
              }}
            >
              Go Back
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
  );
}
