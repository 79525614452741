// apiService.js
import { API_ENDPOINT } from "../constants";

export const logoutService = (authToken) => {
  try {
    fetch(`${API_ENDPOINT}sch-logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    // if (!response.ok) {
    //   console.error("Error during logout:", response.statusText);
    //   // Handle errors here if needed
    // }
  } catch (error) {
    console.error("Error during logout:", error.message);
    // Handle errors here if needed
  }
};
