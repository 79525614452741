import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { API_ENDPOINT } from "../../constants";
import {
  Badge,
  Stack,
  RadioGroup,
  Radio,
  Box,
  Flex,
  Text,
  Button,
  Icon,
  IconButton,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaExclamation } from "react-icons/fa";
import ConfirmationDialog from "../ConfirmationDialog";

const Examination = () => {
  const [questionData, setQuestionData] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  // const [progress, setProgress] = useState(0);
  const [exam, setExam] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);
  const [showHideHint, setShowHideHint] = useState(false);
  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 });
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const { id: exam_id } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const getExamInfo = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getexam/${exam_id}`, requestHeader);

      if (response?.data?.exam) {
        setExam(response?.data?.exam);
      }
      if (response?.data?.exam?.questions) {
        const questionsList = response?.data?.exam?.questions?.map((question) => {
          const options = question.options;
          const keyValuePairs = Object.entries(JSON.parse(options));
          const optionPairs = {
            1: keyValuePairs?.[0]?.[1],
            2: keyValuePairs?.[1]?.[1],
            3: keyValuePairs?.[2]?.[1],
            4: keyValuePairs?.[3]?.[1],
            5: keyValuePairs?.[4]?.[1],
            // 6: keyValuePairs?.[5]?.[1],
          };
          return { ...question, optionPairs, hint: keyValuePairs?.[5]?.[1], selectedAnswer: "" };
        });
        setQuestionData(questionsList);
        setTotalQuestions(questionsList?.length);
        setAnswerResponse(null);
      }
      if (response?.data?.exam?.time_limit > 0 && response?.data?.exam?.type == 1) {
        setTimer({ minutes: response?.data?.exam?.time_limit, seconds: 0 });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrevious = () => {
    setShowHideHint(false);
    setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setShowHideHint(false);
    setCurrentQuestionIndex((prevIndex) => Math.min(questionData.length - 1, prevIndex + 1));
  };

  const handleRadioChange = (answerValue) => {
    setQuestionData(
      questionData.map((obj) => {
        if (obj.question_id === currentQuestion?.question_id) {
          setAnsweredQuestions([...answeredQuestions, currentQuestion?.question_id]);
          return { ...obj, selectedAnswer: answerValue };
        }
        return obj;
      }),
    );
  };

  const handleConfirm = () => {
    onClose();
    submitFinalAnswers();
  };

  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    const submittedAnswers = [...new Set(answeredQuestions)]?.length;
    if (totalQuestions != submittedAnswers) {
      setDialogErrorMessage(
        `You have answered only ${submittedAnswers} out of ${totalQuestions} questions. Do you still want to submit your answers?`,
      );
      onOpen();
    } else {
      submitFinalAnswers();
    }
  };

  const submitFinalAnswers = async () => {
    try {
      const formData = {
        ...questionData.map((q) => {
          return {
            exam_id: exam.id,
            question_id: q.question_id,
            selectedAnswer: q?.selectedAnswer ?? "",
          };
        }),
      };
      const response = await axios.post(`${API_ENDPOINT}submit-answers`, formData, requestHeader);
      if (response?.data?.status === true) {
        setCurrentQuestion(null);
        setAnswerResponse(response?.data);
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
      // setError("Error during login:", error);
    }
  };

  useEffect(() => {
    if (questionData) {
      setCurrentQuestion(questionData[currentQuestionIndex]);

      // setProgress(((currentQuestionIndex + 1) / totalQuestions) * 100);
    }
  }, [questionData, currentQuestionIndex]);

  useEffect(() => {
    if (exam?.type == 1) {
      const interval = setInterval(() => {
        if (timer.minutes === 0 && timer.seconds === 0) {
          clearInterval(interval);
        } else if (timer.seconds === 0) {
          setTimer((prevTimer) => ({
            minutes: prevTimer.minutes - 1,
            seconds: 59,
          }));
        } else {
          setTimer((prevTimer) => ({
            ...prevTimer,
            seconds: prevTimer.seconds - 1,
          }));
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, exam]);

  useEffect(() => {
    if (exam_id) {
      getExamInfo();
    }
  }, [exam_id]);

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
        dialogErrorMessage={dialogErrorMessage}
      />
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        {currentQuestion && exam?.type == 1 && (
          <Flex justify="flex-end">
            <Badge
              colorScheme="green"
              style={{ fontSize: "20px", padding: "8px", borderRadius: "8px", marginBottom: "8px" }}
            >
              {timer.minutes.toString().padStart(2, "0")}:
              {timer.seconds.toString().padStart(2, "0")}
            </Badge>
          </Flex>
        )}

        {currentQuestion && (
          <>
            <Box borderWidth="1px" borderRadius="lg" p="4" mb="4">
              <Text
                as="span"
                fontWeight="bold"
                mb="4"
                mt="4"
                dangerouslySetInnerHTML={{
                  __html: `${currentQuestionIndex + 1}) ${currentQuestion?.question}`,
                }}
              />
              <RadioGroup
                flexDirection="column"
                value={currentQuestion?.selectedAnswer ?? ""}
                onChange={handleRadioChange}
              >
                <Stack direction="column">
                  {Object.entries(currentQuestion?.optionPairs).map(([key, value]) => (
                    <Radio key={key} value={key}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${value}`,
                        }}
                      ></span>
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              {exam?.type == 2 && (
                <>
                  <Button
                    colorScheme="blue"
                    ml="auto"
                    mr="8px"
                    mt="20px"
                    mb="8px"
                    p="4px"
                    w="150px"
                    onClick={() => setShowHideHint(!showHideHint)}
                  >
                    <Icon as={FaExclamation} />
                    Answer Hint
                  </Button>
                  {showHideHint && (
                    <Text
                      as="span"
                      mb="4"
                      mt="4"
                      dangerouslySetInnerHTML={{
                        __html: `${currentQuestionIndex + 1}) ${currentQuestion?.hint}`,
                      }}
                    />
                  )}
                </>
              )}
            </Box>
            <Flex justifyContent="space-between">
              {/* <IconButton
                colorScheme="teal"
                onClick={handlePrevious}
                icon={<ChevronLeftIcon />}
                aria-label="Previous"
                disabled={currentQuestionIndex === 0}
              /> */}
              {/* <IconButton
                colorScheme="teal"
                onClick={handleNext}
                icon={<ChevronRightIcon />}
                aria-label="Next"
                disabled={currentQuestionIndex === questionData.length - 1}
              /> */}

              <Button
                leftIcon={<ChevronLeftIcon />}
                colorScheme="teal"
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>

              <Button
                rightIcon={<ChevronRightIcon />}
                colorScheme="teal"
                onClick={handleNext}
                disabled={currentQuestionIndex === questionData.length - 1}
              >
                Next
              </Button>
            </Flex>

            <Button
              colorScheme="green"
              ml="auto"
              mr="8px"
              mt="20px"
              mb="8px"
              w="250px"
              onClick={handleAnswerSubmit}
            >
              Submit Answers
            </Button>
          </>
        )}
      </Flex>

      {answerResponse && (
        <Flex justifyContent="center" alignItems="center" minHeight="80vh" bg="gray.100">
          <Box
            maxW="lg"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="xl"
            bg="white"
          >
            <Box p={6}>
              <Heading as="h2" size="lg" mb={2}>
                {answerResponse?.message}
              </Heading>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text>
                  Total Questions: <br />
                  {answerResponse?.total_questions}
                </Text>
                <Text>
                  Answered Questions: <br />
                  {answerResponse?.answered_questions}
                </Text>
                <Text>
                  Correct Answers: <br />
                  {answerResponse?.correct_answers}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Examination;
